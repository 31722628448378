import "./tariffs.scss";
import React from "react";
import Tariff from "./tariff";
import ShowMore from "@components/show-more";

const Tariffs = ({ data, isHeaderShown, code, emptyMessage }) => {
	return (
		<section className="grid-container tariffs">
			<div className="row">
				{isHeaderShown && (
					<div className="col-xs-12 col-sm-12 col-md-12">
						<h3 className="tariffs__header">Тарифы</h3>
					</div>
				)}
			</div>
			<div className="row">
				{data && data.length ? (
					<ShowMore
						buttonClassName="tariffs__button-show-more"
						attrReset={code}
					>
						{(data || []).map((item, index) => (
							<div
								className="col-xs-12 col-sm-12 col-md-offset-2 col-md-8"
								key={index}
							>
								<Tariff {...item} className="tariffs__tariff" />
							</div>
						))}
					</ShowMore>
				) : (
					<div className="col-xs-12 col-sm-12 col-md-offset-2 col-md-8 tariffs__message">
						<p>{emptyMessage}</p>
					</div>
				)}
				<div className="col-sm-2 col-md-2" />
			</div>
		</section>
	);
};

Tariffs.defaultProps = {
	isHeaderShown: true,
	emptyMessage:
		"К сожалению, для данной карты в текущем населенном пункте еще не определены тарифы."
};

export default Tariffs;
