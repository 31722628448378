import "./tariffs.scss";
import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import CardCost from "@components/card/card-cost";
import TariffsSection from "@components/card/tariffs";
import Select from "@components/select";
import { connect } from "react-redux";

const TariffsComponent = ({ cityId }) => {
	const cards = useStaticQuery(graphql`
		query {
			allStrapiCards(sort: { fields: order, order: ASC }) {
				nodes {
					id
					code
					description
					strapiId
					title
					cost
					image {
						publicURL
					}
					tariffs {
						name
						id
						bus
						trolleybus
						tram
						cities
						tariff_field {
							id
							label
							value
						}
					}
				}
			}
		}
	`).allStrapiCards.nodes;

	const [tariffsByCity, setTariffsByCity] = useState(undefined);

	const selectedCards = cards.filter(
		card => card.tariffs && card.tariffs.length
	);

	const options = selectedCards.map((item, index) => ({
		value: index,
		label: item.title
	}));

	const [selectedCard, setSelectedCard] = useState(options[0]);
	const { cost, tariffs } = selectedCards[selectedCard.value];

	useEffect(() => {
		const tariffsByCity = tariffs.filter(tariff => {
			return (
				tariff.cities && tariff.cities.length && tariff.cities.includes(cityId)
			);
		});
		setTariffsByCity(tariffsByCity);
	}, [cityId, tariffs, setTariffsByCity]);

	const onChange = value => {
		setSelectedCard(value);
	};

	return (
		<section className="tariffs-separately">
			<div className="grid-container grid-container--eight">
				<div className="row">
					<div className="col-xs-12 col-sm-6 col-md-4 col-md-offset-2">
						<h2>Тарифы</h2>
					</div>
				</div>
				<div className="row">
					<div className="col-xs-12 col-sm-6 col-md-4 col-md-offset-2">
						<Select
							value={selectedCard}
							onChange={onChange}
							options={options}
							isSearchable={false}
							placeholder="Выберите карту"
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-xs-12 col-sm-6 col-md-4 col-md-offset-2">
						<CardCost className="tariffs-separately__card-cost" value={cost} />
					</div>
				</div>
			</div>
			<TariffsSection isHeaderShown={false} data={tariffsByCity} />
		</section>
	);
};

const mapStateToProps = ({ city, staticData: { cities } = {} }) => {
	// TODO: to Redux
	let id;
	if (city && cities && cities.length) {
		const cityId = cities.find(item => item.name === city);
		id = cityId && cityId.strapiId;
	}
	return { cityId: id };
};

const Tariffs = connect(
	mapStateToProps,
	null
)(TariffsComponent);

export default Tariffs;
