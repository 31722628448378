import "./tariff.scss";
import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import icBus from "./ic-bus.svg";
import icTrolley from "./ic-trolley.svg";
import icTram from "./ic-tram.svg";
import icMetro from "./ic-metro.svg";

const icons = {
	bus: icBus,
	trolleybus: icTrolley,
	tram: icTram,
	metro: icMetro
};

const TariffSection = ({ name, value }) => {
	return (
		<div className="tariff__section">
			<p className="tariff__section-header">{name}</p>
			<p className="tariff__section-value">{value}</p>
		</div>
	);
};

const Tariff = props => {
	const { name, tariff_field, className, bus, trolleybus, tram } = props;

	return (
		<div className={cn("tariff", className)}>
			<div className="tariff__inner">
				<div className="tariff__header">
					<h4 className="tariff__header__title">{name}</h4>
					<div className="hidden-xs tariff__header__types">
						{bus && (
							<img className="tariff__icon" src={icons["bus"]} alt="Автобус" />
						)}
						{tram && (
							<img className="tariff__icon" src={icons["tram"]} alt="Трамвай" />
						)}
						{trolleybus && (
							<img
								className="tariff__icon"
								src={icons["trolleybus"]}
								alt="Тролейбус"
							/>
						)}
					</div>
				</div>
				<ol className="tariff__body">
					<div className="hidden-sm hidden-md tariff__header__types">
						<div className="tariff__section">
							<p className="tariff__section-header">Вид транспорта</p>
							<p className="tariff__section-value">
								{bus && (
									<img
										className="tariff__icon"
										src={icons["bus"]}
										alt="Автобус"
									/>
								)}
								{tram && (
									<img
										className="tariff__icon"
										src={icons["tram"]}
										alt="Трамвай"
									/>
								)}
								{trolleybus && (
									<img
										className="tariff__icon"
										src={icons["trolleybus"]}
										alt="Тролейбус"
									/>
								)}
							</p>
						</div>
					</div>
					{tariff_field &&
						tariff_field.map(({ label, value, id }) => (
							<TariffSection key={id} name={label} value={value || "-"} />
						))}
				</ol>
			</div>
		</div>
	);
};

Tariff.propTypes = {
	name: PropTypes.string.isRequired,
	className: PropTypes.string
};

Tariff.defaultProps = {};

export default Tariff;
