import "./card-cost.scss";
import React from "react";
import cn from "classnames";

const CardCost = ({ value, className }) => {
	let view;
	view = value ? (
		<>
			<h4>Стоимость карты:</h4>
			<span className="card-cost__value">{value} &#8381;</span>
		</>
	) : (
		<>
			<h4>Стоимость карты:</h4>
			<span className="card-cost__value">бесплатно</span>
		</>
	);

	return <div className={cn("card-cost", className)}>{view}</div>;
};

export default CardCost;
