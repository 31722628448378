import React from "react";
import Select from "react-select";

import "./select.scss";

const CustomSelect = props => (
	<Select
		className="react-select-container"
		classNamePrefix="react-select"
		{...props}
	/>
);

export default CustomSelect;
